import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";
import { CloudConsoleClient } from "libs/utils/http/client/HttpClient";
import { APIResource, APIMethod } from "libs/utils/http/decorator/UrlDecorator";
import { IdentityApplicationPublishType, IdentityApplicationEnvironmentType, IdentityApplication, IdentityApplicationPublishRequest } from "./PublisherApplicationService";


const formatError = (error: any, defaultMessage: string) => error?.response?.data?.code !== undefined && error.response.data.message ? error.response.data.message : defaultMessage;

@APIResource(CloudConsoleClient, "publisher/applications")
class ApplicationServiceFactory {


    @APIMethod("/:applicationName/gateway/:environmentName/:publishType")
    public publish(publishType: IdentityApplicationPublishType, environmentName: IdentityApplicationEnvironmentType, applicationName: string, request?: IdentityApplicationPublishRequest) {
        const context = apiMethodContext(arguments);
        const method = publishType === "update" ? CloudConsoleClient.put : CloudConsoleClient.post;

        return new Promise<IdentityApplication>((resolve, reject) => {
            method(context, request, {
                params: {
                    applicationName,
                    environmentName,
                    publishType
                }
            }).then(
                response => resolve(response.data),
                error => reject(formatError(error, "Unknown error occured while publishing the application"))
            );
        });
    }
}

const ApplicationService = new ApplicationServiceFactory();

export default ApplicationService;