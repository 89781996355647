import React, { useState, useEffect, createContext, Dispatch } from 'react';
import PropTypes from 'prop-types';
import { withRouter, RouteComponentProps, useHistory } from 'react-router';
import { Container } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { IdentityApplicationEnvironmentType } from './PublisherApplicationService';
import { StatusBadge } from '../../../../legacy-components/badges/StatusBadge';
import AccordionForm, { AccordionSummaryTitle } from '../../../../legacy-components/form/components/accordion-form/AccordionForm';
import { useResourceViewStyles, ResourceViewSkeleton, ResourceViewSectionTitle } from '../../../../legacy-components/resource-view/ResourceView';
import { AsyncLoader } from '../../../../legacy-components/form/action/AsyncAction';
import GenericPageHeader, { GenericPageHeaderSkeleton } from '../../../../legacy-components/page-headers/GenericPageHeader';
import IdentityApplicationDetailsView from './application-views/IdentityApplicationDetailsView';
import InlineIconFormatter from '../../../../legacy-components/formatters/InlineIconFormatter';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import IdentityApplicationConfigurationsView from './application-views/IdentityApplicationConfigurationsView';
import { useIdentityApplicationInfo, IdentityApplicationViewState, IdentityApplicationViewAction } from './application-views/IdentityApplicationInfo';
import { IdentityApplicationSubscriptionsView } from './application-views/IdentityApplicationSubscriptionsView';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';

interface RouterPathProps {
    applicationName?: string
    environmentName?: IdentityApplicationEnvironmentType
}
interface IdentityApplicationViewProps {

}
export interface IIdentityApplicationViewContext {
    state: IdentityApplicationViewState | null,
    dispatch: Dispatch<IdentityApplicationViewAction>
}

export const IdentityApplicationViewContext = createContext<IIdentityApplicationViewContext>({
    state: null,
    dispatch: () => { }
});

const IdentityApplicationDetails = (
    <AccordionSummaryTitle>
        <FormattedMessage defaultMessage="Application Details" />
    </AccordionSummaryTitle>
);

const IdentityApplicationConfigurations = (
    <AccordionSummaryTitle>
        <FormattedMessage defaultMessage="Configurations" />
    </AccordionSummaryTitle>
);

const IdentityApplicationSubscriptions = (
    <AccordionSummaryTitle>
        <FormattedMessage defaultMessage="Subscriptions" />
    </AccordionSummaryTitle>
)

function IdentityApplicationView({ match }: RouteComponentProps<RouterPathProps> & IdentityApplicationViewProps) {
    const styles = useResourceViewStyles();
    const history = useHistory();
    const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment);

    const { state, dispatch, response } = useIdentityApplicationInfo({
        applicationName: match.params.applicationName,
        environmentName: match.params.environmentName
    });

    const [viewContext, setViewContext] = useState({
        state,
        dispatch
    });

    useEffect(() => {
        if(!match.params.environmentName || !identityEnvironment || !history) {
            return;
        }
        if(match.params.environmentName !== identityEnvironment) {
            history.push(`/home/applications`);
        }

    },[match.params.environmentName, identityEnvironment, history])

    useEffect(() => {
        setViewContext({
            state,
            dispatch
        })
    }, [state, dispatch]);

    return (
        <>
        {response?.error && (<Alert severity='error'>The following application is restricted to the following user for the selected team </Alert>)
                }
        <IdentityApplicationViewContext.Provider value={viewContext}>
            <AsyncLoader trigger={!!state.application} state={response} loader={<GenericPageHeaderSkeleton />}>
                {
                    state.application && <GenericPageHeader
                        header={<FormattedMessage defaultMessage="Applications" />}
                        title={state.application.displayName}
                        badge={state.statusBadge && <StatusBadge status={state.statusBadge} />}
                    />
                }
            </AsyncLoader>
            <Container maxWidth="md">
                <AsyncLoader trigger={!!state.application} state={response} loader={<ResourceViewSkeleton />}>
                    {state.application &&
                        <>
                            <AccordionForm id="application-summary">
                                <AccordionForm.type.Section id="application-detail" summary={IdentityApplicationDetails}>
                                    <div className={styles.section}>
                                        <IdentityApplicationDetailsView publishedInfo={state.publishStatus?.[state.application.organisationName]} application={state.application} />
                                    </div>
                                </AccordionForm.type.Section>
                            </AccordionForm>

                            <ResourceViewSectionTitle>
                                <InlineIconFormatter icon={<HelpOutlineOutlinedIcon />}>
                                    <FormattedMessage defaultMessage="Advanced" />
                                </InlineIconFormatter>
                            </ResourceViewSectionTitle>

                            <AccordionForm id="application-advance">
                                <AccordionForm.type.Section id="application-configuration" summary={IdentityApplicationConfigurations}>
                                    <div className={styles.section}>
                                        <IdentityApplicationConfigurationsView />
                                    </div>
                                </AccordionForm.type.Section>
                            </AccordionForm>

                            <AccordionForm id="application-subscriptions">
                                 <AccordionForm.type.Section id="application-configuration" summary={IdentityApplicationSubscriptions}>
                                    <div className={styles.section}>
                                        <IdentityApplicationSubscriptionsView application={state.application}/>
                                    </div>
                                </AccordionForm.type.Section>
                            </AccordionForm>
                        </>
                    }
                </AsyncLoader>
            </Container>
        </IdentityApplicationViewContext.Provider>
    </>)
}

IdentityApplicationView.propTypes = {
    match: PropTypes.any.isRequired
}

export default withRouter(IdentityApplicationView);

