import React from "react";
import MuiTextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "7px 0px",
  },
  labelContainer: {
    display: "flex",
    padding: "7px 0",
    gap: "8px",
  },
  label: {
    fontSize: "large",
    lineHeight: "21px",
    color: "#464B52",
  },
  required: {
    color: "#DA212C",
    height: "16px",
  },
  inputContainer: {
    display: "flex",
    "& div": {
      borderRadius: "4px",
    },
  },
  muiinputtext: {
    border: "1px solid #E0E1E9",
    height: "48px",
    font: "inherit",
    padding: "0px 12px",
    width: "100%",
    borderBottom: "solid 1px #0063a3",
  },
});

interface InputContainerProps {
  placeholder: string;
  label: string;
  required?: boolean;
  value: string;
  helperText?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  setError: (error: boolean) => void;
  validate?: ValidationRules;
}

interface ValidationRules {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  allowSpace?: boolean;
}

export const InputContainer: React.FC<InputContainerProps> = ({
  placeholder,
  required,
  label,
  value,
  validate = {},
  onChange,
  name,
  helperText,
  setError,
}) => {
  const [isError, setIsError] = React.useState("");
  const [touched, setTouched] = React.useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (touched) {
      const validationError = validateInput(inputValue);
      setIsError(validationError);
      setError(validationError !== "");
    }
    onChange(e);
  };
  const validateInput = (inputValue: any) => {
    if (validate.required && !inputValue) {
      return "This field is required";
    }

    if (!validate.allowSpace && String(inputValue).indexOf(" ") !== -1) {
      return "The input has one or more space(s). Please remove the space(s).";
    }

    if (validate.pattern && !new RegExp(validate.pattern).test(inputValue)) {
      return "Invalid format";
    }

    if (validate.minLength && inputValue.length < validate.minLength) {
      return `The input must be at least 3 characters in length.`;
    }

    if (validate.maxLength && inputValue.length > validate.maxLength) {
      return `The input exceeds the 64 character limit.`;
    }

    return "";
  };

  const handleBlur = () => {
    setTouched(true);
    const validationError = validateInput(value);
    setError(validationError !== "");
    setIsError(validationError);
  };

  const classes = useStyles();
  const isSuccess = !isError && value && value.length > 0 && touched;

  return (
    <div style={{ fontWeight: 400 }} className={classes.root}>
      <div className={classes.labelContainer}>
        <label className={classes.label}>{label}</label>
        {required && (
          <div style={{ fontWeight: 600 }} className={classes.required}>
            *
          </div>
        )}
      </div>
      <MuiTextField
        className={classes.inputContainer}
        name={name}
        inputProps={{
          className: classes.muiinputtext,
        }}
        placeholder={placeholder}
        value={value}
        helperText={isError}
        onChange={handleChange}
        onBlur={handleBlur}
        error={isError === "" ? false : true}
        variant="standard"
      />
    </div>
  );
};
