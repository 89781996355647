import React, { useState } from "react";
import { Box, Chip, createStyles, IconButton,  makeStyles, Tooltip, Typography } from "@material-ui/core";
import NAWrap from "legacy-components/form/action/NAWrap";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import { FormattedMessage } from "react-intl";
import { AccordionContent } from "legacy-components/form/components/accordion-form/AccordionForm";
import { ResourceAbility } from "libs/security/authorization";
import EditIcon from '@material-ui/icons/Edit';
import CompactDialog from "legacy-components/dialog/CompactDialog";
import ThrottlingForm from "./ThrottlingForm";
import InlineIconFormatter from "legacy-components/formatters/InlineIconFormatter";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Helper from "pages/home/helper/Helper";
import SpikeArrestHelper from "pages/home/helper/SpikeArrestHelper";
import QuotaHelper from "pages/home/helper/QuotaHelper";

const useStyles = makeStyles(({ spacing, palette }) =>
createStyles({
    quotaValue: {
        textTransform: 'lowercase'
    }
}));
const Throttling = (props: any) => {

    const classes = useStyles();
    const [isShowUpdate, toggleShowUpdate] = useState(false);
    const [isShowSpikeArrestHelper, toggleSpikeArrestHelper]=  useState(false);
    const [isShowQuotaHelper, toggleQuotatHelper]=  useState(false);
    
    const HeaderControls = props.isShared ? null : (
        <ResourceAbility can="create" resource={({ Proxy }) => Proxy.deploy.environments[props.env?.environmentName]}>
            <Tooltip title={"This feature is deprecated now"} placement="left" arrow>
                <span>
                    <IconButton size="small" edge="end" disabled  onClick={() => {toggleShowUpdate(true)}}>
                        <EditIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </ResourceAbility>
    );

    return(
        <AccordionContent size="small" header={<><FormattedMessage defaultMessage="Throttling Configuration" /><Box component={"span"} mr={1}/><Chip label="deprecated" size="small"/></>} controls={HeaderControls} >
            <FormViewInliner gutter="gutter-bottom" title={
                    <InlineIconFormatter icon={<HelpOutlineOutlinedIcon onClick={() => {toggleSpikeArrestHelper(true)}}/>}>
                        <FormattedMessage defaultMessage="Spike Arrest" />
                    </InlineIconFormatter>}>
                    <Helper open={isShowSpikeArrestHelper} handleClose={() => {toggleSpikeArrestHelper(false)}} children={<SpikeArrestHelper/>}></Helper>
                <Typography variant="body1" className={classes.quotaValue}>
                    <NAWrap value={props.deployment?.throttling?.spikeArrestEnabled} showMessage={true} message="No Limit">
                    {props.deployment?.throttling?.spikeArrestLimit} requests / {props.deployment?.throttling?.spikeArrestTimeunit} 
                    </NAWrap>
                </Typography>
            </FormViewInliner>
            <FormViewInliner gutter="gutter-bottom" title={
                    <InlineIconFormatter icon={<HelpOutlineOutlinedIcon onClick={() => {toggleQuotatHelper(true)}}/>}>
                        <FormattedMessage defaultMessage="Subscription Quota" />
                    </InlineIconFormatter>
                }>
                <Helper open={isShowQuotaHelper} handleClose={() => {toggleQuotatHelper(false)}} children={<QuotaHelper/>}></Helper>
                <Typography variant="body1" className={classes.quotaValue}>
                    <NAWrap value={props.deployment?.throttling?.subscriptionQuotaEnabled} showMessage={true} message="No Limit">
                        {props.deployment?.throttling?.subscriptionQuotaLimit} requests / {props.deployment?.throttling?.subscriptionQuotaInterval}  {props.deployment?.throttling?.subscriptionQuotaTimeunit} 
                    </NAWrap>
                </Typography>
            </FormViewInliner>
            <CompactDialog open={isShowUpdate} onClose={() => {toggleShowUpdate(false)}} title={<FormattedMessage defaultMessage="Throttling Configuration" />}>
                <ThrottlingForm  onUpdateSuccess={props.onUpdateSuccess} throttling={props.deployment?.throttling} env={props.env?.environmentName} deployment={props.deployment}/>
            </CompactDialog>
        </AccordionContent>
    )
};

export default Throttling;