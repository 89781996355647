import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Radio,
  Theme,
} from "@material-ui/core";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import {
  IApplication,
  ProductService,
} from "pages/home/consumer/service/ProductService";
import { ProductDetail } from "pages/home/products/components/Product.model";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Alert, Skeleton } from "@material-ui/lab";
import ServiceApplicationIcon from "legacy-components/icons/ServiceApplicationIcon";
import ApplicationIcon from "legacy-components/icons/ApplicationIcon";
import { ErrorMessage } from "modus/components/error-message/ErrorMessage";
import { ApplicationCreationPage } from "pages/home/applications/pages/application-creation-page/ApplicationCreationPage";
import { useHistory } from "react-router";
import SubscriptionService from "pages/home/consumer/service/SubscriptionService";
import { getAPICodeMessge } from "./ErroCodeMapping";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { Link } from "react-router-dom";
import Dialog from "legacy-components/dialog/Dialog";
import { useSelector } from "react-redux";
import Loader from "modus/components/misc/Loader";
import ReviewIcon from "modus/icons/ReviewIcon";

interface UnsubscribedApplicationListProps {
  product: ProductDetail;
}

const useApplicationStyles = makeStyles((theme: Theme) =>
  createStyles({
    options: {
      backgroundColor: "#fff",
      width: "100%",
      borderRadius: "4px",
      padding: "8px",
      margin: "8px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      border: "1px solid #E0E1E9",
    },
    skeleton: {
      margin: "8px",
    },
    root: {},
    actions: {
      display: "flex",
      justifyContent: "end",
      padding: "16px",
    },
    listContainer: {
      maxHeight: "483px",
      marginTop: "16px",
      overflowY: "auto",
      overflowX: "hidden",
    },
  })
);

export const UnsubscribedApplicationList: React.FC<
  UnsubscribedApplicationListProps
> = React.memo(({ product }) => {
  const [response, { subscribe }] = useAsyncAction(() =>
    ProductService.getApplications(`${product?.name}`, "UNSUBSCRIBED")
  );
  const identityEnvironment = useSelector((state: any) => state?.common?.identityEnvironment );

  const [selectedApplication, setSelectedApplication] =
    useState<IApplication>();
  const [subscribeResponse, { subscribe: subscribeToApplication }] =
    useAsyncAction(() =>
      SubscriptionService.createSubscription(product?.name as string, {
        applicationName: selectedApplication?.applicationName as string,
        gatewayName: selectedApplication?.environment as string,
        businessContext: null,
      })
    );

  const classes = useApplicationStyles();
  const history = useHistory();

  useEffect(() => {
    
    if (subscribeResponse?.loaded && subscribeResponse.value) {
      setTimeout(() => {
        const currentUrl = window.location.href;
         if(!currentUrl.includes('/subscribe')) {
          return;
         }
        
        if(subscribeResponse?.value?.data?.subscriptionId) {
            history.push(`/home/subscriptions/${subscribeResponse?.value?.data?.subscriptionId}`);
        } else {
            history.push(`/home/consumer/products/${product?.name}`);
        }
        
      }, 3000);
    }
    
  }, [history, history?.location?.pathname, product.name, subscribeResponse.loaded, subscribeResponse.value]);

  useEffect(() => {
    if (!product?.name || !subscribe) {
      return;
    }
    subscribe();
  }, [product.name, subscribe]);

  const gotoProductSummary = () => {
    history.push(`/home/consumer/products/${product?.id}`);
  };

  return (
    <Box className={classes.root}>
      {response?.loading && (<ApplicationListSkeleton />)}
      {(response?.loading || subscribeResponse?.loading) && 
        (<Loader showBackDrop={true} />)
      }
      {!response?.loading &&
        response?.value?.data &&
        response?.value?.data?.length >= 1 && (
          <Box>
            {subscribeResponse?.errorValue && (
              <Alert severity="error">
                {getAPICodeMessge(subscribeResponse.errorValue)}
              </Alert>
            )}
            {subscribeResponse?.value && (
              <Dialog
                open={subscribeResponse?.loaded}
                title={`Subscribe to ${product?.name}`}
              >
                <Box>
                  <Box style={{ textAlign: "center", padding: "20px" }}>
                    {(subscribeResponse?.value?.data?.status === "PENDING_REVIEW" )? 
                        <ReviewIcon
                          style={{ color: "#0063A3", fontSize: "6.25rem" }}
                        /> : 
                        <CheckCircle
                          style={{ color: "#689248", fontSize: "6.25rem" }}
                        />
                    }
                     
                    <Box
                      marginTop="30px"
                      marginBottom="30px"
                      style={{ fontSize: "18px" }}
                    >
                      The Subscription with &nbsp;
                      <Link
                        to={`/home/consumer/products/${product.id}`}
                        style={{
                          fontWeight: "bold",
                          color: "#0063A3",
                          textDecoration: "none",
                        }}
                      >
                        {product?.name}
                      </Link>{" "}
                      &nbsp;
                      {subscribeResponse?.value?.data?.status ===
                      "PENDING_REVIEW"
                        ? "is submitted for review."
                        : "completed successfully."}{" "}
                    </Box>
                  </Box>
                </Box>
              </Dialog>
            )}
            <Box className={classes.listContainer}>
              {response?.value?.data?.filter((app) => app.environment === identityEnvironment)?.map((app: IApplication) => 
                <Box
                  onClick={() => setSelectedApplication(app)}
                  className={classes.options}
                  key={app.applicationName}
                >
                  <Box style={{ padding: "0 10px" }}>
                    {app.type === "application" ? (
                      <ApplicationIcon
                      style={{ fontSize: "1.5rem", fill: "#217CBB" }}
                    />
                    ) : (
                      <ServiceApplicationIcon
                        style={{ fontSize: "1.5rem", fill: "#217CBB" }}
                      />
                    )}
                  </Box>

                  <Box flexGrow={1} style={{ padding: "0 10px" }}>
                    {app.applicationName}
                  </Box>
                  <Box style={{ padding: "0 10px" }}>
                    <Radio
                      color="primary"
                      size="small"
                      data-testid={`${app.applicationName}-radio-btn`}
                      checked={
                        selectedApplication?.applicationName ===
                        app.applicationName
                      }
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={classes.actions}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={gotoProductSummary}
              >
                Cancel
              </Button>
              <Box paddingLeft={"15px"} />
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedApplication}
                onClick={() => subscribeToApplication()}
              >
                Subscribe
              </Button>
            </Box>
          </Box>
        )}
      {!response?.loading &&
        response?.loaded &&
        (!response?.value?.data || response?.value?.data?.length <= 0) && (
          <ErrorMessage
            message={
              <Box>
                <Box margin={"15px"}>No applications found</Box>
                <ApplicationCreationPage
                  variant="outlined"
                  onSuccess={() => subscribe()}
                />
              </Box>
            }
          />
        )}
    </Box>
  );
});

export const ApplicationListSkeleton = () => {
  const classes = useApplicationStyles();
  return (
    <Box className={classes.root}>
      {_.range(0, 5).map((index) => (
        <Skeleton
          height={54}
          width={"100%"}
          className={classes.skeleton}
          key={index}
        />
      ))}
    </Box>
  );
};
