import React from 'react'
import { ButtonProps, Button, useTheme  } from '@material-ui/core';

export function WarnButton(props: ButtonProps) {
    const { palette } = useTheme();
    return (
            <Button {...props} style={{backgroundColor: palette.warning.main}} />
    )
}

