import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  Dispatch,
  createContext,
} from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  AccordionSummaryTitle,
  AccordionContent,
} from "legacy-components/form/components/accordion-form/AccordionForm";
import { StatusBadge } from "legacy-components/badges/StatusBadge";
import { FormattedMessage } from "react-intl";
import FormViewInliner from "legacy-components/form/container/FormViewInliner";
import NAWrap from "legacy-components/form/action/NAWrap";
import CopyToClipboard from "legacy-components/form/action/CopyToClipboard";
import { ResourceAbility } from "libs/security/authorization";
import {
  Button,
  IconButton,
  Link,
  Tooltip,
  FormControl,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ActionInliner from "legacy-components/form/action/ActionInliner";
import ProxyEnvironmentDialog, {
  ProxyEnvironmentDialogRef,
} from "./environment-configs/ProxyEnvironmentDialog";
import { useDispatch, useSelector } from "react-redux";

import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ProxyEnvironmentConfirmationDialog, {
  ProxyEnvironmentConfirmationDialogRef,
} from "./environment-configs/ProxyEnvironmentConfirmationDialog";
import { ProxyDeployFormData } from "../models/proxy.model";

import * as actions from "../../../../store/actions/actions";
import Throttling from "./Throttling";
import SecurityConfig from "./SecurityConfig";
import SSLConfig from "./SSLConfig";
import Alert from "@material-ui/lab/Alert";
import NoAuthConfig from "./NoAuthConfig";
import Helper from "../../helper/Helper";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import InlineIconFormatter from "../../../../legacy-components/formatters/InlineIconFormatter";
import TimeoutConfigHelper from "../../helper/TimeoutConfigHelper";
import { useFeatureAbility } from "libs/security/authorization/Permission";
import { Feature } from "libs/security/authorization/FeatureAbility";
import UnifiedAPIService, { UnifiedAPI } from "services/UnifiedAPIService";

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    accordionSummary: {
      padding: "10px 15px",
      margin: 0,
      "&.Mui-expanded": {
        margin: " 0 !important",
        padding: "0 15px",
      },
    },
    accordionDetails: {
      padding: 0,
    },
    root: {
      padding: spacing(2),
      textAlign: "center",
      width: "100%",
    },
    noConfigMessage: {
      color: palette.text.secondary,
    },
    noConfigAction: {
      padding: spacing(3, 0),
    },
  })
);

interface DeployUpdateAction {
  type: "deployUpdate";
  payload: {
    env: string;
    deployment?: any;
  };
}

type ProxyConfigAction = DeployUpdateAction;

interface ProxyConfigState {
  deployments: string | null;
}

const ProxyConfigContext = createContext<{
  state: ProxyConfigState | null;
  dispatch: Dispatch<ProxyConfigAction>;
}>({
  state: null,
  dispatch: () => {},
});

const ProxyEnvironment = (props: any) => {
  const classes = useStyles();

  let proxyDetails = useSelector((state: any) => state.proxyManager.proxy);
  const dialogRef = useRef<ProxyEnvironmentDialogRef>();
  const confirmDialogRef = useRef<ProxyEnvironmentConfirmationDialogRef>();
  const deploymentContext = useContext(ProxyConfigContext);
  const [isShowTimeoutConfigHelper, toggleTimeoutConfigHelper] =
    useState(false);
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const onSubmit = () => {
    setIsSubmitted(true);
  };

  const [status, setStatus] = useState("");

  useEffect(() => {
    const deployFailedStatus = ["UPLOAD_FILE_FAILED", "DEPLOY_FAILED"];
    function computeDeployStatus(deployment: any) {
      if (!deployment) return "nopublish";
      if (deployment.deployStatus === "DEPLOY_COMPLETED") {
        return "unpublish";
      } else if (
        deployFailedStatus.some((value) => value === deployment.deployStatus)
      ) {
        return "trypublish";
      }
      return "nopublish";
    }
    setStatus(computeDeployStatus(props.deployment));
  }, [props.deployment]);

  const Controls = () => {
    return props.deployment ? (
      <StatusBadge status={props.deployment.deployStatus} />
    ) : (
      <></>
    );
  };

  const onEdit = useCallback(
    (prefill: boolean) => {
      return dialogRef?.current?.open(
        {
          backendUrl: "", // deployment.[]?.backendUrl
        },
        prefill
      );
    },
    [dialogRef]
  );

  const onCreateEnv = useCallback(
    () => dialogRef?.current?.open(),
    [dialogRef]
  );

  const onUndeploy = useCallback(
    () => confirmDialogRef?.current?.open(),
    [confirmDialogRef]
  );

  const handleEdit = useCallback(() => onEdit(false), [onEdit]);
  const handleRedeploy = useCallback(() => onEdit(true), [onEdit]);

  const handleDeploySubmit = useCallback(
    (data: ProxyDeployFormData) => {
      // let env = props.env;
      deploymentContext.dispatch({
        type: "deployUpdate",
        payload: {
          env: props.env,
          deployment: data.__formResponse?.deploy,
        },
      });
      proxyDetails = { ...proxyDetails };
      proxyDetails.apiDeployments.push(data?.__formResponse?.deploy);
      dispatch({ type: actions.storeProxy, payload: proxyDetails });
      props.onSuccess();
    },
    [deploymentContext, props.env]
  );

  const handleUndeploySubmit = useCallback(() => {
    deploymentContext.dispatch({
      type: "deployUpdate",
      payload: {
        env: props.env,
        deployment: undefined,
      },
    });
    props.onSuccess();
  }, [deploymentContext, props.env]);
  const HeaderControls = props.isShared ? null : (
    <ResourceAbility
      can="create"
      resource={({ Proxy }) =>
        Proxy.deploy.environments[props.env.environmentName]
      }
    >
      <Tooltip title={<FormattedMessage defaultMessage="Edit configuration" />}>
        <span>
          <IconButton
            size="small"
            edge="end"
            disabled={status === "nopublish" || props?.deployment?.allowReDeployment === false}
            onClick={handleEdit}
          >
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
    </ResourceAbility>
  );

  const ContentActions = props.isShared ? null : (
    <ActionInliner>
      {(status === "unpublish" || status === "nopublish") && (
        <ResourceAbility
          can="create"
          resource={({ Proxy }) =>
            Proxy.deploy.environments[props.env.environmentName]
          }
        >
          <Button
            variant="outlined"
            onClick={onUndeploy}
            disableElevation
            disabled={status === "nopublish"}
          >
            UNDEPLOY{" "}
          </Button>
        </ResourceAbility>
      )}
      {status === "trypublish" && (
        <ResourceAbility
          can="create"
          resource={({ Proxy }) =>
            Proxy.deploy.environments[props.env.environmentName]
          }
        >
          <Button
            disableElevation
            variant="contained"
            onClick={handleRedeploy}
            color="primary"
          >
            REDEPLOY
          </Button>
        </ResourceAbility>
      )}
    </ActionInliner>
  );

  const ConfigureDeploy = props.isShared ? null : (
    <ResourceAbility
      can="create"
      resource={({ Proxy }) =>
        Proxy.deploy.environments[props.env.environmentName]
      }
    >
      <Button
        startIcon={<SettingsOutlinedIcon />}
        onClick={onCreateEnv}
        variant="outlined"
        color="primary"
      >
        <FormattedMessage defaultMessage="Configure" />
      </Button>
    </ResourceAbility>
  );
  const hasFeature = useFeatureAbility({
    id: Feature.DirectEndpoint,
  });
  const CopyBackendUrl = (
    <CopyToClipboard
      size="small"
      edge="end"
      text={props.deployment?.backendUrl}
    />
  );

  const [url, setUrl] = useState<string>("");

  const CopyDeployedUrl = (
    <CopyToClipboard size="small" edge="end" text={url} />
  );
  const getEnvironmentName = () => {
    if (props.env.environmentName === "eu-west") {
      return "Ireland (eu-west)";
    } else {
      return props.env.environmentName;
    }
  };

  const isEnabledInit = props?.deployment?.directEndpoint ? true : false;
  const [enabled, setEnabled] = useState<boolean>(isEnabledInit);

  const getDirectEndpointValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnabled(event.target.checked);
    UnifiedAPIService.post(
      UnifiedAPI.parseRequestURL(UnifiedAPI.direct_endpoint, {
        apiName: proxyDetails?.apiName,
        environment: props.env.environmentName,
        directEndpoint: enabled ? "false" : "true",
      })
    ).then((response) => {
      props.onSuccess && props.onSuccess();
    });
  };

  useEffect(() => {
    if (enabled) setUrl(props.deployment?.backendUrl);
    else setUrl(props.deployment?.deployedUrl);
  }, [enabled]);
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <AccordionSummaryTitle controls={<Controls />}>
          {props.env.environmentName === "eu-west"
            ? "Ireland (eu-west)"
            : props.env.environmentName}
        </AccordionSummaryTitle>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        <NAWrap value={props.deployment?.isAdvanced === true}>
          <Alert severity="info">
            This API Proxy is deployed via Advance User API customized bundle.
            Hence, Security/SSL Settings are not supported as this will override
            the customized policies{" "}
          </Alert>
        </NAWrap>
      </AccordionDetails>

      <AccordionDetails className={classes.accordionDetails}>
        {props.deployment && (
          <AccordionContent
            size="small"
            header={
              <FormattedMessage defaultMessage="Deployment Configuration" />
            }
            controls={HeaderControls}
            actions={ContentActions}
          >
            <FormViewInliner
              gutter="gutter-bottom"
              title={<FormattedMessage defaultMessage="Target Endpoint" />}
              controls={CopyBackendUrl}
            >
              <Typography variant="body1">
                <NAWrap value={props.deployment?.backendUrl} showMessage={true}>
                  <Link
                    className="link"
                    target="_blank"
                    href={props.deployment?.backendUrl}
                  >
                    {props.deployment?.backendUrl}
                  </Link>
                </NAWrap>
              </Typography>
            </FormViewInliner>

            <FormViewInliner
              gutter="gutter-bottom"
              title={<FormattedMessage defaultMessage="Deployed Endpoint" />}
              controls={CopyDeployedUrl}
            >
              <Typography variant="body1">
                <NAWrap value={url} showMessage={true}>
                  <Link className="link" href={url}>
                    {url}
                  </Link>
                </NAWrap>
              </Typography>
            </FormViewInliner>

            <FormViewInliner
              gutter="gutter-bottom"
              title={
                <InlineIconFormatter
                  icon={
                    <HelpOutlineOutlinedIcon
                      onClick={() => {
                        toggleTimeoutConfigHelper(true);
                      }}
                    />
                  }
                >
                  <FormattedMessage defaultMessage="Connection Timeout" />
                </InlineIconFormatter>
              }
            >
              <Helper
                open={isShowTimeoutConfigHelper}
                handleClose={() => {
                  toggleTimeoutConfigHelper(false);
                }}
                children={<TimeoutConfigHelper />}
              ></Helper>
              <Typography variant="body1">
                {props.deployment?.timeout}&nbsp;s
              </Typography>
            </FormViewInliner>
            {hasFeature && (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      onChange={(event) => {
                      getDirectEndpointValue(event)
                      }}
                    
                      checked={enabled}
                    />
                  }
                  label="Enable Direct Endpoint"
                />
              </FormControl>
            )}
          </AccordionContent>
        )}
        {!props.deployment && (
          <div className={classes.root}>
            <div className={classes.noConfigMessage}>
              <Typography variant="caption">
                <FormattedMessage defaultMessage="Deployment configuration not available" />
              </Typography>
            </div>
            <div className={classes.noConfigAction}>{ConfigureDeploy}</div>
          </div>
        )}
      </AccordionDetails>

      {props.deployment && props.env.throttling && (
        <AccordionDetails className={classes.accordionDetails}>
          <Throttling
            isShared={props.isShared}
            env={props.env}
            deployment={props.deployment}
            status={status}
            onUpdateSuccess={props.onSuccess}
          />
        </AccordionDetails>
      )}
      {props.deployment && (
        <AccordionDetails className={classes.accordionDetails}>
          <SecurityConfig
            isShared={props.isShared}
            env={props.env}
            deployment={props.deployment}
            status={status}
            onUpdateSuccess={props.onSuccess}
          />
        </AccordionDetails>
      )}
      {props.deployment && (
        <AccordionDetails className={classes.accordionDetails}>
          <SSLConfig
            isShared={props.isShared}
            env={props.env}
            deployment={props.deployment}
            status={status}
            onUpdateSuccess={props.onSuccess}
          />
        </AccordionDetails>
      )}

      {props.deployment &&
        props.deployment.deployStatus === "DEPLOY_COMPLETED" && (
          <AccordionDetails className={classes.accordionDetails}>
            <NoAuthConfig
              apiName={proxyDetails?.apiName}
              environmentName={props.env.environmentName}
              deployedUrl={props.deployment.deployedUrl}
            />
          </AccordionDetails>
        )}
      <ProxyEnvironmentConfirmationDialog
        proxyName={proxyDetails?.apiName ?? ""}
        env={props.env.environmentName}
        ref={confirmDialogRef}
        onSubmit={handleUndeploySubmit}
        onSuccess={props.onSuccess}
      />

      <ProxyEnvironmentDialog
        proxyName={proxyDetails?.apiName ?? ""}
        deployment={props.deployment}
        env={props.env.environmentName}
        ref={dialogRef}
        onSubmit={handleDeploySubmit}
        onSuccess={props.onSuccess}
      />
    </Accordion>
  );
};

export default ProxyEnvironment;
