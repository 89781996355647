import React, { Ref, useCallback, useImperativeHandle, FC, PropsWithRef, forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, makeStyles, createStyles, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { useCompactDialog } from '../../../../legacy-components/dialog/UseCompactDialog';
import { IdentityApplicationEnvironmentType, IdentityApplication } from './PublisherApplicationService';
import { FormattedMessage } from 'react-intl';
import ActionInliner from '../../../../legacy-components/form/action/ActionInliner';
import { ResourceViewContentSkeleton, ResourceViewNoData } from '../../../../legacy-components/resource-view/ResourceView';
import { AsyncLoader } from '../../../../legacy-components/form/action/AsyncAction';
import { useIdentityApplicationInfo, IdentityApplicationCredentialsView, CopyIdentityApplicationValues } from './application-views/IdentityApplicationInfo';
import FormViewInliner from '../../../../legacy-components/form/container/FormViewInliner';
import NAWrap from '../../../../legacy-components/form/action/NAWrap';

const useStyles = makeStyles(({ spacing }) => {
    return createStyles({
        header: {
            position: "relative"
        },
        typeText: {
            padding: spacing(3, 0, 2, 0)
        }
    })
});

export interface IdentityApplicationDetailDialogRef {
    open(): void
}

interface IdentityApplicationDetailDialogProps {
    ref?: Ref<IdentityApplicationDetailDialogRef | undefined>,
    application: IdentityApplication;
}

const ApplicationCredentialsViewer: FC<{
    application: IdentityApplication
}> = ({ application }) => {
    const { state, response } = useIdentityApplicationInfo({
        applicationName: application.applicationName,
        environmentName: application.organisationName
    });

    const [skeletonWidth] = useState("calc(100% - 72px)");

    return (
        <AsyncLoader trigger={!!state.application} state={response} loader={<ResourceViewContentSkeleton width={skeletonWidth} variant="list" />}>
            {state.publishedGateway ?
                <>
                    <FormViewInliner variant="list" gutter="gutter-bottom" title={<FormattedMessage defaultMessage="Name" />} controls={<CopyIdentityApplicationValues value={application.applicationName} />}>
                        <Typography variant="body1">
                            <NAWrap value={application.applicationName} showMessage={true}>
                                {application.applicationName}
                            </NAWrap>
                        </Typography>
                    </FormViewInliner>

                    <IdentityApplicationCredentialsView applicationName={application.applicationName} variant="list" publishedGateway={state.publishedGateway} />
                </> :
                <>
                    <ResourceViewNoData>
                        <FormattedMessage defaultMessage="Application Credentials not available" />
                    </ResourceViewNoData>
                </>
            }
        </AsyncLoader>
    )
}

const IdentityApplicationDetailDialog: FC<PropsWithRef<IdentityApplicationDetailDialogProps>> = forwardRef(({ application }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
        >

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Application Credentials" />
                </DialogTitle>
            </div>
            <DialogContent>
                <ApplicationCredentialsViewer application={application} />
            </DialogContent>
            <DialogActions>
                <ActionInliner>
                    <Button onClick={handleClose}>
                        <FormattedMessage defaultMessage="Close" />
                    </Button>
                </ActionInliner>
            </DialogActions>
        </Dialog>
    );
});

IdentityApplicationDetailDialog.propTypes = {
    application: PropTypes.shape({
        applicationId: PropTypes.string.isRequired,
        applicationName: PropTypes.string.isRequired,
        organisationName: PropTypes.oneOf<IdentityApplicationEnvironmentType>(["trimble-pre-prod", "trimble-prod"]).isRequired
    }).isRequired,
}

export default IdentityApplicationDetailDialog

