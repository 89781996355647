
import { from } from "rxjs";
import ConsumerClient from "./ConsumerClient";
import "reflect-metadata";
import { APIMethod, APIResource } from "libs/utils/http/decorator/UrlDecorator";
import { apiMethodContext } from "libs/utils/http/builder/UrlConfig";

export interface IApplicationKey {
  applicationName: string;
  consumerKey: string;
  consumerSecret: Array<string>;
}

@APIResource(ConsumerClient, "/applications")
class ApplicationServiceImpl {
  @APIMethod("/:applicationName/gateway/:gatewayName/keys")
  public getApplicationKeys(applicationName: string, gatewayName: string) {
    return from(
      ConsumerClient.get<IApplicationKey>(apiMethodContext(arguments), {
        params: {
          applicationName,
          gatewayName,
        },
      })
    );
  }
}
const ApplicationService = new ApplicationServiceImpl();

export default ApplicationService;
