import React, { FC } from "react";
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTheme } from "@material-ui/core";
import IdentityApplicationList from "./IdentityApplicationList";
import IdentityApplicationSingleList from "./IdentityApplicationSingleList";
import { ResourceAbility } from "libs/security/authorization";

const mapState = () => ({

});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface IdentityViewProps {

}
interface RouterPathProps {
}

const IdentityView: FC<IdentityViewProps & PropsFromRedux & RouteComponentProps<RouterPathProps>> = ({ match }) => {

    return (
        <ResourceAbility can="read" resource={({ Application }) => [Application.list.environment["trimble-prod"], Application.listByUser.environment["trimble-prod"]]} fallBack={<IdentityApplicationSingleList />} >
            <IdentityApplicationList />
        </ResourceAbility >

    );
}

export default withTheme(withRouter(connector(IdentityView)));