import { Button, ButtonProps } from "@material-ui/core"
import { ResourceAbility } from "libs/security/authorization"
import React, { useCallback } from "react";
import AddIcon from "@material-ui/icons/Add";
import { FormattedMessage } from "react-intl";
import Dialog, { useDialogProps } from "legacy-components/dialog/Dialog";
import ApplicationBasicForm, { AppResponse } from "libs/resources/identity/applications/application-form/ApplicationBasicForm";
import { useLocation } from "react-router";
import ResourceAppBasicForm from "libs/resources/identity/applications/application-form/ResourceAppBasicForm";

interface ApplicationCreationPageProps extends ButtonProps {
    onSuccess: (app: AppResponse) =>void;
}
export const ApplicationCreationPage:React.FC<ApplicationCreationPageProps> = ({onSuccess, ...props}) => {

    const {open: openAppCreate, toggle} = useDialogProps();
    let location = useLocation();
   

    const toApplicationCreate = useCallback(() => {
        toggle();
    }, [toggle]);

    return (
        <>
        <ResourceAbility can="create" resource={({ Application }) => [
            Application.create.environment["trimble-prod"],
            Application.create.environment["trimble-pre-prod"]
        ]}>
            <Button startIcon={<AddIcon />} onClick={toApplicationCreate} variant="contained" color="primary" {...props}>
                <FormattedMessage defaultMessage="Add Application" />
            </Button>
        </ResourceAbility>
         <Dialog open={openAppCreate} title="Create New Application" onClose={toggle}>
                <div>
                    <ResourceAppBasicForm onCancel={toggle} onSuccess={onSuccess}></ResourceAppBasicForm>
                </div>
            </Dialog>
        </>
    )
}