import React, { useCallback } from "react";
import {
  IconButton,
  Tooltip,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";

import AlertService from "legacy-components/alert/AlertService";
import Typography from "legacy-components/typography/Typography";
import Dialog, { useDialogProps } from "legacy-components/dialog/Dialog";
import { useHistory } from "react-router";
import EventService from "../service/EventService";

export interface DeleteEventConfProps {
  subscriptionId: string;
  subscriptionTrn: string;
  onChange: ()=> void;
}

export const DeleteEventConf: React.FC<DeleteEventConfProps> = ({
  subscriptionId,
  subscriptionTrn,
  onChange
}) => {
  const { open, toggle } = useDialogProps();
  const history = useHistory();

  const deleteEventSubscriptionConf = useCallback(() => {
    EventService.deleteEventSubscription( subscriptionId, subscriptionTrn)
      .toPromise()
      .then(
        (output) => {
          AlertService.success({
            message: `Event subscription configuration is deleted`,
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
          setTimeout(() => {
            onChange();
          }, 1000);
        },
        (error) => {
          AlertService.error({
            message: "Failed to Delete Event subscription configuration",
            isAutoHide: true,
            id: "UNSUBSCRIBER",
          });
          toggle();
        }
      );
  }, [subscriptionId, toggle, subscriptionTrn, onChange]);

  return (
    <>
      <Tooltip title={"Delete"}>
        <span>
          <IconButton onClick={toggle} size="small" data-testid={`${subscriptionId}-delete-btn`}>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open} title="Delete Event Subscription Configuration" onClose={toggle}>
        <Divider></Divider>
        <>
          <DialogContent>
            <Typography type="body" align="center">
              Are you sure you want to delete this event subscription configuration?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={toggle}>
              Cancel
            </Button>
            <Button onClick={deleteEventSubscriptionConf} color="primary" variant="contained"> Delete </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default DeleteEventConf;