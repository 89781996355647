import React, { useCallback, useImperativeHandle, FC, PropsWithRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, makeStyles, createStyles, Typography } from '@material-ui/core';
import ProxyUndeployForm from '../../proxy-forms/ProxyUndeployForm';
import { FormattedMessage } from 'react-intl';
import { ProxyUndeployFormData } from '../../models/proxy.model';
import { useCompactDialog } from '../../../../../legacy-components/dialog/UseCompactDialog';

const useStyles = makeStyles(({ spacing }) => {
    return createStyles({
        header: {
            position: "relative"
        },
        typeText: {
            padding: spacing(3, 0, 2, 0)
        }
    })
});

export interface ProxyEnvironmentConfirmationDialogRef {
    open(): void
}

const ProxyUndeployFormContent = ({ proxyName }: {
    proxyName: string
}) => {
    const styles = useStyles();
    const proxyNameFormatter = () => <strong>{proxyName}</strong>;

    return (
        <>
            <Typography component="p">
                <FormattedMessage values={{
                    proxyName: proxyNameFormatter
                }} defaultMessage="This action cannot be undone. This will permanently remove the <proxyName>name</proxyName> deployment and cannot be accessed elsewhere." />
            </Typography>

            <Typography className={styles.typeText} variant="body2" component="p">
                <FormattedMessage values={{
                    proxyName: proxyNameFormatter
                }} defaultMessage="Type <proxyName>name</proxyName> to confirm." />
            </Typography>
        </>
    )
}

const ProxyUndeployFormViewer = ({ proxyName, env, onSuccess, onCancel }: any) => {

    return (
        <ProxyUndeployForm proxyName={proxyName} formContent={<ProxyUndeployFormContent proxyName={proxyName} />} env={env} onCancel={onCancel} onSuccess={onSuccess} />
    )
}

const ProxyEnvironmentConfirmationDialog: FC<PropsWithRef<any>> = forwardRef(({ proxyName, env, onSubmit, onSuccess }, ref) => {
    const style = useStyles();
    const { maxWidth, open, scroll, setOpen } = useCompactDialog();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleSubmit = useCallback((data: ProxyUndeployFormData) => {
        setOpen(false);
        onSubmit(data);
    }, [onSubmit, setOpen]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useImperativeHandle(ref, () => ({
        open: () => handleClickOpen()
    }));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={maxWidth}
            open={open}
            scroll={scroll}
            onClose={handleClose}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >

            <div className={style.header}>
                <DialogTitle>
                    <FormattedMessage defaultMessage="Undeploy this proxy?" />
                </DialogTitle>
            </div>
            <ProxyUndeployFormViewer proxyName={proxyName} env={env} onCancel={handleClose} onSuccess={onSuccess} onSubmit={handleSubmit} />
        </Dialog>
    );
});

ProxyEnvironmentConfirmationDialog.propTypes = {
    proxyName: PropTypes.string.isRequired,
    env: PropTypes.string.isRequired
}

export default ProxyEnvironmentConfirmationDialog

