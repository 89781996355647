import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, useParams, useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import { Box, Grid } from "@material-ui/core";
import { useAsyncAction } from "legacy-components/form/action/AsyncAction";
import ConsumerSubscriptionService from "../../services/ConsumerSubscriptionService";
import { EventDiscoveryPage } from "pages/home/events/pages/EventDiscoveryPage";
import { APIErrorMessage } from "modus/components/error-message/ErrorMessage";
import { Skeleton } from "@material-ui/lab";
import { ApplicationAccordion, ProductAccordion, ServiceAccordion } from "../../components/subscription-details/SubscriptionDetails";
import Unsubscriber from "../../components/Unsubscriber/Unsubscriber";
import { useSelector } from "react-redux";
import { useIdentityEnvironmentFilter } from "../../components/identity-environment-filter/IdentityEnvironmentFilter";
import GenericPageTitle from "modus/components/generic-page-title/GenericPageTitle";
export interface IDeployment {
  protocol: string;
  endpoint: string;
}
export interface IEventDeployment {
  environment: string;
  deployments: Array<IDeployment>
}

export interface IServiceVersion {
  versionName: string;
  eventId: string;
  eventDeployments: Array<IEventDeployment>;
}

export interface IService {
  description?: string;
  displayName: string;
  serviceName: string;
  serviceVersions: Array<IServiceVersion>;
}

export interface IEvent {
  subscriptionTrn: string
  description?: string;
  filter?: any;
  serviceDeployment: string;
  serviceVersion: string;
}

export interface ISubscriptionSummary {
  applicationId?: string;
  applicationCreatedDate: string;
  applicationCreatedBy?: string;

  applicationName: string;
  organisationName: string;
  productId: string;
  productName: string;
  productTeamName: string;
  servicesConfigurations: Array<IService>;
  status: string;
  subscriberEmail: string;
  subscriptionId: string;
  subscriptionRequestedTime: string;
  eventsConfigurations?: IEvent[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  skeleton: {
    transform: 'scale(1, 0.98)'
  }
}));

export const SubscriptionSummaryPage: React.FC = ({}) => {
  const { subscriptionId } = useParams<any>();
  const {filterEventDeployments} = useIdentityEnvironmentFilter();
  const history = useHistory();
  const identityEnvironment = useSelector(
    (state: any) => state?.common?.identityEnvironment
  );

  const [response, { subscribe }] = useAsyncAction(() =>
    ConsumerSubscriptionService.getSubscriptionSummary(subscriptionId)
  );

  useEffect(() => {
    if(!identityEnvironment || !response?.value?.data ) {
      return;
    }
    if(response?.value?.data?.organisationName !== identityEnvironment) {
      history.push(`/home/subscriptions`);
    }
  },[identityEnvironment,response?.value?.data?.organisationName]);

  useEffect(() => {
    if (!subscriptionId) {
      return;
    }
    subscribe();
  }, [subscriptionId]);


  const handleReload = () => {
    subscribe();
  }

  const classes = useStyles();

  if(response?.errorValue) {
    return <APIErrorMessage error={response?.errorValue} />
  }
  return (
    <div className={classes.root}>
      <Box paddingLeft={"20px"} paddingRight={"20px"}>
        <GenericPageTitle
          title={<FormattedMessage defaultMessage="Subscriptions" />}
          controls={ <Unsubscriber
            subscriptionId={response.value?.data.subscriptionId || ""}
            applicationName={response.value?.data.applicationName || ""}
            isIcon={false}
            reloadData={()=>{history.push(`/home/subscriptions`);}}
          />}
          noSkeleton
        />
      </Box>
      <Box paddingLeft={"20px"} paddingRight={"20px"}>
      <Grid container spacing={4} >
        <Grid item xs={12} md={6}>
            {response?.loaded && (<ProductAccordion subscription={response?.value?.data} />)}
            {response?.loading && <Skeleton height={280} width="100%" className={classes.skeleton}/>}
        </Grid>
        <Grid item xs={12} md={6}>
        {response?.loaded && (<ApplicationAccordion subscription={response?.value?.data} />)}

          {response?.loading && <Skeleton height={280} width="100%" className={classes.skeleton} />}
        </Grid>
      </Grid>

      <Box marginBottom={"30px"} />
      {/**
       * Service Listing
       **/}
      {response?.loaded && (<ServiceAccordion productId={response?.value?.data?.productId} services={response?.value?.data?.servicesConfigurations} />)}
      {response?.loading && <Skeleton height={400} width="100%" className={classes.skeleton} />}

      {response?.loaded && (<EventDiscoveryPage applicationId={response?.value?.data?.applicationId} subscriptionId={subscriptionId} eventDeployments={filterEventDeployments(response?.value?.data?.eventsConfigurations)} serviceConfig={response?.value?.data?.servicesConfigurations} onChange={handleReload}/>)}
      {response?.loading && <Skeleton height={400} width="100%" className={classes.skeleton} />}
      </Box>
    </div>
  );
};

export default withRouter(SubscriptionSummaryPage);
