import { AxiosResponse } from "axios";
import { AsyncActionState, useAsyncAction } from "legacy-components/form/action/AsyncAction";
import { ConsumerProductService } from "pages/home/consumer/service/ConsumerProductService";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { ProductDetail } from "../Product.model";
import { Controller } from "modus/utilities/ViewController";


export interface ProductDetailModel {
    response: AsyncActionState<AxiosResponse<ProductDetail> | null>;
    subscribe?: (stream?: boolean) => () => void;
}   


export const useProductDetailsController:Controller<{},ProductDetailModel> = () => {

    const {productId} = useParams<any>();
    const [response, {subscribe}] = useAsyncAction(() => ConsumerProductService.getProduct(productId));
  
    useEffect(() => {
        if (!productId || !subscribe){
            return;
        }
        subscribe();
    }, [productId, subscribe]);

    return {
        response,
        subscribe
    };
  
}