import { Box, createStyles, Grid, InputLabel, makeStyles, MenuItem, Paper, Select, Theme, Typography, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { APIDeployment, Service, Version, EventDeployment } from "./Product.model";
import Table, { TableColumn } from "legacy-components/table/Table";
import CopyToClipboard from "legacy-components/form/action/CopyToClipboard";
import { ServiceVersionDetails } from "pages/home/cloud-services/ServiceVersionDetails";
import ModusIcon from "legacy-components/icons/ModusIcon";
import clsx from 'clsx';
import { useIdentityEnvironmentFilter } from "pages/home/subscriptions/components/identity-environment-filter/IdentityEnvironmentFilter";

export interface ProductSummaryViewModel {
  productName: string;
  services: Service[]
}

const Actions = (props: { data: any }) => {
  return (
    <CopyToClipboard size="small" edge="end" text={props?.data?.endpoint} key={props?.data?.endpoint} className={`copy-to-clipboard ${props?.data?.endpoint}`}/>
  );
};


const useProductServicesViewerStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      padding: "12px 12px 11px 12px",
      borderBottom: `1px solid #CBCDD6`,
    },
    list: {
      padding: 0,
      maxHeight: `calc(60vh)`,
      overflowY: "auto",

    },
    expandMoreIcon: {
      color: `#464B52 !important`,
      marginRight: "15px"
    }
  }));


export const ProductServicesViewer: React.FC<ProductSummaryViewModel> = ({ productName, services }) => {
  const initialService = services?.[0];
  const initialVersion = initialService?.versions[0];
  const classes = useProductServicesViewerStyles();
  const [selectedDisplayService, setSelectedDisplayService] = React.useState<string>(initialVersion ? `${initialVersion.versionName}` : '');
  const [selectedVersion, setSelectedVersion] = React.useState<Version | undefined>(initialVersion);
  const [selectedService, setSelectedService] = React.useState<Service | undefined>(initialService);
  const [selectedType, setSelectedType] = React.useState<string>(initialVersion?.apiDeployments?.length ? "api" : "events");

  const onChange  = (service: Service, version: Version) => {
    setSelectedDisplayService(`${version.versionName}`);
    setSelectedService(service);
    setSelectedVersion(version);
    setSelectedType(version.apiDeployments?.length ? "api" : "events");
  }

  return (
    <Box>
      <Grid container>
        <Grid item md={6} xs={12} >
          <Box py={2}>
            {services?.length >= 1 && (
              <div>
              <InputLabel style={{marginBottom:"5px", fontSize: "14px", fontWeight: 400}}>
                Service Version
              </InputLabel>
              <Select 
                IconComponent={(props) =>  <ModusIcon
                  name={'expand_more'}
                  {...props}
                  className={clsx([props.className, classes.expandMoreIcon])}
                />}
                MenuProps={{ classes: { list: classes.list } }}
                style={{backgroundColor: '#fff'}} fullWidth variant="outlined" value={selectedDisplayService} >
                {services?.map((service: Service) => {
                  return [
                    ...service.versions?.map((version) => {
                      return (
                        <MenuItem 
                          onClick={() => onChange(service, version)} 
                          value={`${version.versionName}`}
                          className={classes.option}
                          style={{fontSize: "14px", fontWeight: 400}}
                        >{version.versionName}</MenuItem>
                      );
                    })
                  ];
                })}
              </Select>
              </div>
            )}
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box p={2}>
          {(services?.length >= 1 && 
          (selectedVersion?.apiDeployments && selectedVersion.apiDeployments?.length > 0 ||
          selectedVersion?.eventDeployments && selectedVersion.eventDeployments?.length > 0)
          ) && (
            <div>
            <InputLabel style={{marginBottom:"5px", fontSize: "14px", fontWeight: 400}}>
              Service Type
            </InputLabel>
          <Select
            style={{backgroundColor: '#fff'}}
            fullWidth
            variant="outlined"
            value={selectedType}
            IconComponent={(props) =>  <ModusIcon
              name={'expand_more'}
              {...props}
              className={clsx([props.className, classes.expandMoreIcon])}
            />}
            MenuProps={{ classes: { list: classes.list } }}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => setSelectedType(e.target.value as string)}
          >
            {selectedVersion?.apiDeployments && selectedVersion.apiDeployments?.length > 0 && (<MenuItem className={classes.option} style={{fontSize: "14px", fontWeight: 400}} value={"api"}>APIs</MenuItem>)}
            {selectedVersion?.eventDeployments && selectedVersion.eventDeployments?.length > 0 && (<MenuItem className={classes.option} style={{fontSize: "14px", fontWeight: 400}}value={"events"}>Events</MenuItem>)}
          </Select>
          </div>
          )}
          </Box>
        </Grid>
      </Grid>
      {selectedVersion && <ServiceVersionDetails productName={productName} version={selectedVersion} type={selectedType}/>}
    </Box>
  );
};

interface IAPIServiceEndpoints {
  apiDeployments: APIDeployment[]
}

interface IEventServiceEndpoints {
  eventDeployments: EventDeployment[]
}

export interface TransformedDeployment {
  endpoint?: string|null;
  geography?: string|null;
  isDeprecated?: boolean|undefined;
  provider?: string|null;
  type?: string|null;
  environment?: string|null;
}

export const APIServiceEndpoints = ({apiDeployments}:IAPIServiceEndpoints) => {

  const [deploymentList, setDeploymentList] = React.useState<TransformedDeployment[]>();
  useEffect(() => {
    const extractedDeploymentlist:TransformedDeployment[] = [];
    apiDeployments.forEach(deployment => {
      deployment.instances.forEach(instance => {
        extractedDeploymentlist.push({
          ...instance,
          environment: (deployment.environment ?? "N/A"),
        });
      });
    });

    setDeploymentList(extractedDeploymentlist);
  },[apiDeployments]);

  const {filterEndpointDeployments} = useIdentityEnvironmentFilter();

  return (
    <Paper style={{marginTop:"20px"}}>
      <Box p={2}>
        <Typography style={{fontSize:"14px", fontWeight: 'bold'}}>Service Endpoints</Typography>
        <Typography variant="body1" style={{fontSize:"14px", fontWeight: 'normal'}}>Please select the appropriate Service Endpoint needed for your Environment.</Typography>
      </Box>
      <Box p={2} style={{width:'100%'}}>
        <Table 
            rowData={filterEndpointDeployments(deploymentList)} 
            width='100%' height={"300px"} pagination={false}
            getRowNodeId={data => data.endpoint} 
            frameworkComponents={{Actions}}>
          <TableColumn field="endpoint" flex={2} cellRenderer={"Link"}/>
           <TableColumn field="environment"  flex={1}/>
          <TableColumn field="geography" headerName="Region" flex={1}/>
          <TableColumn field="action"  width={100} cellRenderer={"Actions"}/>
        </Table>
      </Box>
    </Paper>
  );
}

export const EventServiceEndpoints = ({eventDeployments}:IEventServiceEndpoints) => {
  const [deploymentList, setDeploymentList] = React.useState<TransformedDeployment[]>();

  useEffect(() => {
    const extractedDeploymentlist:TransformedDeployment[] = [];
    eventDeployments.forEach(deployment => {
      deployment.deployments.forEach(instance => {
        extractedDeploymentlist.push({
          ...instance,
          environment: (deployment.environment ?? "N/A"),
        });
      });
    });

    setDeploymentList(extractedDeploymentlist);
  },[eventDeployments]);

  return (
    <Paper style={{marginTop:"20px"}}>
      <Box p={2}>
        <Typography style={{fontSize:"14px", fontWeight: 'bold'}}>Event Endpoints</Typography>
        <Typography variant="body1" style={{fontSize:"14px", fontWeight: 'normal'}}>Please select the appropriate Event Endpoint needed for your Environment.</Typography>
      </Box>
      <Box p={2} style={{width:'100%'}}>
        <Table
          rowData={deploymentList}
          width='100%' height={"300px"} pagination={false}
          getRowNodeId={data => data.endpoint}
          frameworkComponents={{Actions}}>
          <TableColumn field="endpoint" flex={2} cellRenderer={"Link"}/>
          <TableColumn field="environment"  flex={1}/>
          <TableColumn field="protocol" headerName="Protocol" flex={1}/>
          <TableColumn field="action"  width={100} cellRenderer={"Actions"}/>
        </Table>
      </Box>
    </Paper>
  );
}

export const ProductServices = ProductServicesViewer;