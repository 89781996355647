import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, Select, MenuItem, TextField, Typography, IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { TrimbleColors } from 'legacy-components/TrimbleColors';
import { resolveEventFilterToText } from './filter';
import { resolveTextToFilter } from './filter';
import EventService from '../service/EventService';
import { useValidateFilterCondition } from './EventFilterValidation';

interface EventDrawerProps {
    open: boolean;
    onClose: () => void;
    event_details: any;
    subscriptionId: string;
    onChange: ()=> void;
}

const EventDrawer: React.FC<EventDrawerProps> = ({ open, onClose, event_details, subscriptionId, onChange }) => {
    const [selectedServiceInDrawer, setSelectedServiceInDrawer] = useState<string>('');
    const [selectedDeploymentInDrawer, setSelectedDeploymentInDrawer] = useState<string>('');
    const [selectedDescriptionInDrawer, setSelectedDescriptionInDrawer] = useState<string>('');
    const [filter, setFilter] = useState<string>('');
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const { validateFilterCondition } = useValidateFilterCondition();
    const [isFilterValid, setIsFilterValid] = useState<boolean>(true);

    // Replace with your actual service and deployment lists
    const [serviceList, setServiceList] = useState<string[]>(['DataOcean - v1', 'Processing Framework - v1', 'Processing Framework - v2']);
    const [serviceDeploymentList, setServiceDeploymentList] = useState<string[]>(['DataOcean - v1', 'Processing Framework - v1', 'Processing Framework - v2']);

    useEffect(() => {
        if (event_details != null){
            setSelectedServiceInDrawer(event_details.serviceVersion);
            setSelectedDeploymentInDrawer(event_details.serviceDeployment);
            setSelectedDescriptionInDrawer(event_details.description);
            setFilter(resolveEventFilterToText(event_details.filter));
        }
        
    }, [event_details]);

    useEffect(() => {
        setIsFormValid(
            selectedDescriptionInDrawer.trim() !== '' && 
            filter !== undefined &&
            isFilterValid === true
        );
    }, [selectedDescriptionInDrawer, filter, isFilterValid]);

    const handleServiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedServiceInDrawer(event.target.value as string);
    };

    const handleDeploymentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedDeploymentInDrawer(event.target.value as string);
    };

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        if (name === 'description') {
            setSelectedDescriptionInDrawer(value);
        }
        if (name === 'filter'){
            setFilter(value);
            if(value===''){
                setIsFilterValid(true);
            }else{
            setIsFilterValid(false);
            }
        }
    };


    const handleFormSubmit =(event: React.FormEvent) => {
        event.preventDefault();
        if (isFormValid) {
            
            try {
                const requestPayload  = {
                    description: selectedDescriptionInDrawer,
                    filter: filter !== '' ? resolveTextToFilter(filter) : null,
                };

                EventService.updateEventSubscription(requestPayload, subscriptionId, event_details.subscriptionTrn).toPromise()
                .then(()=>{
                    onChange();
                })
                onClose();
                
            } catch (error) {
                console.error("Error creating subscription:", error);
            }
        }        
    };

    const handleVerifyClick = () => {
        //api call to verify filter scenarios
        const validationResult = validateFilterCondition(filter);
        setIsFilterValid(validationResult);
    };

    return (
        <Drawer anchor="right" open={open} onClose={onClose} style={{ width: 950 }}>
            <Box p={2} style={{ width: 933, paddingLeft: 54, maxHeight: '100vh', overflowY: 'auto' }}>
                <Typography variant="h6" style={{ marginBottom: 16, marginLeft:1 }}>
                    Update Configuration
                </Typography>
                <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 300, marginBottom: 16 }}>
                    Create a new event specific to your environment and service
                </Typography>
                <form onSubmit={handleFormSubmit}>
                    <Box mb={2} style={{}}>
                        <Typography style={{ marginBottom: 8, fontSize: '18px', fontWeight: 300 }}>
                            Service Versions
                        </Typography>
                        <Select
                            disabled
                            fullWidth
                            variant="outlined"
                            value="service_version"
                            style={{width: 800, backgroundColor: '#e0dce4'}}
                        >
                        <MenuItem value="service_version">{selectedServiceInDrawer}</MenuItem>
                        </Select>

                        <Typography style={{ marginBottom: 8, marginTop: 50, fontSize: '18px', fontWeight: 300 }}>
                            Service Deployment
                        </Typography>
                        <Select
                        disabled
                            fullWidth
                            variant="outlined"
                            value="service_deploy"
                            style={{width: 800, backgroundColor: '#e0dce4'}}
                        >
                        <MenuItem value="service_deploy">{selectedDeploymentInDrawer}</MenuItem>
                        </Select>

                        <Typography style={{ marginBottom: 8, marginTop: 50, fontSize: '18px', fontWeight: 300 }}>
                            Description
                        </Typography>
                        <TextField
                            name="description"
                            onChange={handleFormChange}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            placeholder="Add short description here"
                            value={selectedDescriptionInDrawer}
                            variant="outlined"
                            style={{ backgroundColor: '#fff', borderRadius: 4, width: 800}}
                        />
                    </Box>
                    <Typography style={{ marginBottom: 5, marginTop: 50, fontSize: '18px', fontWeight: 600 }}>
                        Filter (Optional)
                        <IconButton size="medium" style={{ marginLeft: 8 }}>
                            <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2} style={{width: 800}}>
                        <TextField
                            name="filter"
                            onChange={handleFormChange}
                            fullWidth
                            rows={4}
                            value={filter}
                            placeholder="Add your filter here"
                            variant="outlined"
                            style={{ backgroundColor: '#fff', borderRadius: 4, flex: 1 }}
                        />
                        <Button
                            variant="outlined"
                            onClick={handleVerifyClick}
                            style={{
                                marginLeft: 16,
                                border: `2px solid ${TrimbleColors.BlueDark}`,
                                color: TrimbleColors.BlueDark,
                                backgroundColor: 'transparent',
                                textTransform: 'none',
                                padding: '12px 24px',
                                fontSize: '20px',
                                minWidth: '120px'
                            }}
                        >
                            Verify
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" mt={2} style={{ marginTop: 100, marginRight: 50 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={!isFormValid}
                            style={{
                                backgroundColor: TrimbleColors.BlueDark,
                                color: '#FFFFFF',
                                textTransform: 'none',
                                padding: '12px 24px',
                                fontSize: '20px',
                                minWidth: '120px',
                                opacity: isFormValid ? 1 : 0.5,
                                cursor: isFormValid ? 'pointer' : 'not-allowed'
                            }}
                        >
                            Update Configuration
                        </Button>
                    </Box>
                </form>
            </Box>
        </Drawer>
    );
};

export default EventDrawer;
